import React, {Component} from 'react';
import Cursor from "./Cursor";
import Target from "./Target";
import videoCrispy from '../../assets/videos/crispy.mp4'
import videoMelty from '../../assets/videos/melty.mp4'

class Question extends Component {

    constructor(props) {
        super(props);
        this.videoCrispyRef = React.createRef();
        this.videoMeltyRef = React.createRef();
        this.state = {
            targetTopClass: 'top',
            targetBottomClass: 'bottom',
            readyToRumble: true,
            reset_cursor: false
        }
    }

    componentDidMount() {  
        this.preloadMelty();
        this.preloadCrispy(); 
    }

    preloadMelty = () => {
        const melty = this.videoMeltyRef.current;
        const req = new XMLHttpRequest();
        req.open('GET', videoMelty, true);
        req.responseType = 'blob';

        req.onload = function() {
        // Onload is triggered even on 404
        // so we need to check the status code
        if (this.status === 200) {
            var videoBlob = this.response;
            var vid = URL.createObjectURL(videoBlob); // IE10+
            // Video is now downloaded
            // and we can set it as source on the video element
            melty.src = vid;
        }
        }
        req.onerror = function() {
        // Error
        }

        req.send();
    }

    preloadCrispy = () => {
        const crispy = this.videoCrispyRef.current;
        const req = new XMLHttpRequest();
        req.open('GET', videoCrispy, true);
        req.responseType = 'blob';

        req.onload = function() {
        // Onload is triggered even on 404
        // so we need to check the status code
        if (this.status === 200) {
            var videoBlob = this.response;
            var vid = URL.createObjectURL(videoBlob); // IE10+
            // Video is now downloaded
            // and we can set it as source on the video element
            crispy.src = vid;
        }
        }
        req.onerror = function() {
        // Error
        }

        req.send();
    }

    nextQuestion = (id) => {
        switch (id) {
            case 0:
                this.videoMeltyRef.current.play();
                this.videoMeltyRef.current.style.visibility = 'visible';
                this.setState({
                    targetBottomClass: 'bottom animated fadeOut',
                    targetTopClass: 'top animated zoomOut',
                    answer_id: id
                });
                break;
            case 1:
                this.videoCrispyRef.current.play();
                this.videoCrispyRef.current.style.visibility = 'visible';
                this.setState({
                    targetTopClass: 'top animated fadeOut',
                    targetBottomClass: 'bottom animated zoomOut',
                    answer_id: id
                });
                break;
            default:
                break;
        }
    
    };

    crispyAnimationEnd = () => {
        this.videoCrispyRef.current.style.visibility = 'hidden';
        this.videoCrispyRef.current.currentTime = 0;
        this.props.nextQuestion(this.state.answer_id);
        this.setState({
            targetTopClass: 'top',
            targetBottomClass: 'bottom',
            reset_cursor: this.props.active
        })
    }

    meltyAnimationEnd = () => {
        this.videoMeltyRef.current.style.visibility = 'hidden';
        this.videoCrispyRef.current.currentTime = 0;
        this.props.nextQuestion(this.state.answer_id)
        this.setState({
            targetTopClass: 'top',
            targetBottomClass: 'bottom',
            reset_cursor:  this.props.active
        })
    }

    render() {
        const { active, questions } = this.props;
        return (
            <div className="view">
                <header>{ questions[active].title }</header>
                <div className="content">
                    <video 
                        preload='metadata' 
                        muted={true} 
                        playsInline={true} 
                        className='video' 
                        ref={ this.videoCrispyRef } 
                        onEnded={this.crispyAnimationEnd}
                    />

                    <video 
                        preload='metadata' 
                        muted={true} 
                        playsInline={true} 
                        className='video' 
                        ref={ this.videoMeltyRef } 
                        onEnded={this.meltyAnimationEnd}
                        src={videoCrispy}
                    />

                    { this.state.readyToRumble && <Target class={this.state.targetTopClass} question={questions[active].answers[0]} /> }
                    <Cursor
                        resetCursor={this.state.reset_cursor}
                        onReady={ () => this.setState({readyToRumble:true})}
                        handleDrop={this.nextQuestion}
                        ready={this.state.readyToRumble}
                    />
                    { this.state.readyToRumble && <Target class={this.state.targetBottomClass} question={questions[active].answers[1] } /> }
                </div>
            </div>
        );
    }
}

export default Question