import React, {Component} from 'react';


class Target extends Component {
    render() {
        return (
            <div className={this.props.class}>
                { this.props.question.answer }
            </div>
        );
    }
}

export default Target