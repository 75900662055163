import React from 'react'
import { Keyframes, animated, Spring } from 'react-spring/renderprops'
import logo from '../../assets/images/logo.png'
import Style from './welcome.module.scss';
import DarkChoc from '../../assets/images/dark-choc-reflet.png'
import { Popup } from '../shared/Popup';
import ellipse from '../../assets/images/ellipse.svg';
import tuto from '../../assets/images/tuto.png';
import Hammer from 'react-hammerjs';

const WelcomeAnimation = Keyframes.Spring([
    { opacity: 1, width: '0px', height: '0px', top: '50%', left: '50%' },
    { opacity: 1, width: '320px', height: '320px', top: '50%', left: '50%' },
    { opacity: 1, width: '100px', height: '100px', top: '9', left: '50%' },
]);

export default class Welcome extends React.Component {

    constructor( props ) {
        super(props);
        this.state = {
            displayPopup: false,
            showTuto: false
        }
    }

    handleSwipe = (e) => {
        if( e.direction === 8 ) {
            this.setState({
                showTuto: true
            })
        }
    }

    render() {
        return (
            <div
                
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: 'hidden',
                    padding: 10,
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative'
                }}
            >
                <Hammer onSwipe={this.handleSwipe} direction="DIRECTION_VERTICAL">
                <div>
                <WelcomeAnimation native config={{ duration: 700 /*, easing: Easing.linear*/ }}>
                    {props => (
                        <animated.div
                            className={Style.welcome__animation}
                            style={{  ...props }}
                        >
                            <img src={logo} alt=""/>
                        </animated.div>
                    )}
                </WelcomeAnimation>

                <animated.div>

                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        delay= '2400'>
                        { props =>
                            <div onClick={()=> this.setState({displayPopup: true})} className={Style.info} style={{ ...props }}>
                                ?
                            </div>
                        }
                    </Spring>

                    <Spring
                        from={{ opacity: 0 }}
                        to={{ opacity: 1 }}
                        delay= '2200'>
                        {   props =>
                            <div className={Style.text} style={{ ...props }}>
                                <span>Dark & furious*</span>
                                <h2>QUEL DARK <br/> CHOCOVORE ES-TU ?</h2>
                            </div>
                        }
                    </Spring>

                    <Spring
                        from={{ opacity: 0, transform: 'translateX(100%)' }}
                        to={{ opacity: 1, transform: 'translateX(0)'  }}
                        delay= '2200'>
                        {props => <img className={Style.darkChoc} style={{bottom:'-30px',...props}}src={DarkChoc} alt=""/> }
                    </Spring>


                    <Spring
                        from={{ opacity: 0,  transform: 'translateY(100%)'  }}
                        to={{ opacity: 1,  transform: 'translateY(0)'  }}
                        delay= '2500'>
                        {   props =>
          
                            <div 
                                className="arrow_link"  
                                style={{ ...props }}
                                onClick={ () => this.setState({showTuto:true}) }>
                                Commencer
                                <div className="wrapper">
                                    <span className="arrow" />
                                    <span className="arrow" />
                                    <span className="arrow" />
                                </div>
                            </div>
                        }
                    </Spring>
                </animated.div>

                { this.state.showTuto && <Popup>
                    <div style={{position:'relative',width:'100%'}}>
                        <img className="bubble" alt='img' src={ellipse} style={{width:'100%'}}/>
                        <img className="tuto" alt='img' style={{
                            position:"absolute",
                            left:'50%',
                            top:'50%',
                            transform:'translateX(-50%) translateY(-50%)',
                            maxWidth: '120px'
                        }} src={tuto} />
                    </div>
                    <h1>Tutoriel</h1>
                    <h3>Glisse le bouton <br/> vers le haut ou le bas <br/> pour choisir la réponse</h3>
                    <button 
                    style={{ margin: '50px auto 0'}} 
                    onClick={ () => this.props.startQuizz(true) } 
                    type="button">OK</button>
                </Popup> }

                { this.state.displayPopup && <Popup>
                    <p>*Dark & Furious = Sombre et furieux</p>
                    <hr />
                    <h2>Mentions légales</h2>
                    <p>Kellogg n’enregistre, ne traite, ne transmet et ne transfère aucune des données consommateurs, y compris l’adresse IP.</p>
                    <p>Pour votre santé, pratiquez une activité physique régulière. <a style={{color: '#fff'}} href="http://www.mangerbouger.fr/" target="_blank" rel="noopener noreferrer">www.mangerbouger.fr</a></p>
                    <button onClick={()=> this.setState({displayPopup: false})} type="button">OK</button>
                </Popup> }
                </div>
                </Hammer>
            </div>
        )
    }
}