import React, {Component} from 'react';
import logo from '../../assets/images/logo.png'
import Draggable from 'react-draggable';
import ClassNames from 'classnames/bind';

class Cursor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            position:{ x:0,y:0 },
            panning: true,
            hideCursor: false,
            resetCursor: false
        }
        this.cursor = React.createRef();
    }

    componentDidMount() {
        window.document.body.addEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
            passive: false
        });
    }

    handlePreventTouchmoveWhenPanning = (event) => {
        if (this.state.panning) {
            event.preventDefault();
        }
    };

    componentWillUnmount () {
        window.document.body.removeEventListener('touchmove', this.handlePreventTouchmoveWhenPanning, {
            passive: false
        });
    }

    componentDidUpdate(prevProps) {
        if( prevProps.resetCursor !== this.props.resetCursor ) {
            this.setState({
                centerCursor: true,
                hideCursor: false,
                ready: false
            }, () => setTimeout( () => this.setState( {ready: true} ) , 450 ) )
        }
    }

    render() {

        const classes = ClassNames({ 
            'cursor': true ,
            'animated fadeOut': this.state.hideCursor,
            'animated bounceIn': ( this.state.centerCursor && !this.state.hideCursor ) && !this.state.ready ,
        });

        return (
            <Draggable
                handle=".cursor"
                position={ (this.state.centerCursor && !this.state.hideCursor) && {x:0, y:0} }
                onStart={this.start}
                onDrag={this.handleDrag}
                onStop={this.handleStop}
            >
                <div ref={this.cursor} className={classes}><img src={logo} alt=""/></div>
            </Draggable>
        );
    }

    start = (e) => {
        e.preventDefault();
        this.setState({
            cursorStartPos : {
                data: document.querySelector('.cursor').getBoundingClientRect()
            }
        }, () =>  this.cursor.current.classList.add('zoom') )
    };

    handleStop = (e, ui) => {
        e.preventDefault();
        const topDrop = document.querySelector('.top').getBoundingClientRect();
        const bottomDrop = document.querySelector('.bottom').getBoundingClientRect();
            const { handleDrop } = this.props;
            if( ui.y < 0 ) {
                // Drag Top
                if( ( this.state.cursorStartPos.data.bottom + ui.y) < topDrop.bottom ) {
                    this.setState({hideCursor: true})
                    handleDrop(0);
                }
            } else {
                // Drag bottom
                if( (this.state.cursorStartPos.data.top + ui.y ) > bottomDrop.top ) {
                    this.setState({hideCursor: true})
                    handleDrop(1);
                }
            }
    };

    handleDrag = (e, ui) => {
        e.preventDefault();
        const topDrop = document.querySelector('.top').getBoundingClientRect();
        const bottomDrop = document.querySelector('.bottom').getBoundingClientRect();
        if( ui.y < 0 ) {
            if( ( this.state.cursorStartPos.data.bottom + ui.y) < topDrop.bottom ) {
                document.querySelector('.top').classList.add('animated','tada')
            } else {
                document.querySelector('.top').classList.remove('animated','tada')
            }
        } else {
            // Drag bottom
            if( (this.state.cursorStartPos.data.top + ui.y ) > bottomDrop.top ) {
                document.querySelector('.bottom').classList.add('animated','tada')
            } else {
                document.querySelector('.bottom').classList.remove('animated','tada')
            }
            
        }
    }
}

export default Cursor