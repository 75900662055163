import React, { Component } from 'react'
import logo from '../../assets/images/logo.png';
import Kellogg from '../../assets/images/kellog.png'
import { Popup } from '../shared/Popup';
import Style from './../welcome/welcome.module.scss';
import Hammer from 'react-hammerjs';

export default class EndComponent extends Component {

    constructor( props ) {
        super(props);
        this.state = {
            displayPopup: false,
        }
    }

    handleSwipe = (e) => {
        if( e.direction === 8 ) {
            this.props.startQuizz(false)
        }
    }

    render() {
        return (
            <Hammer onSwipe={this.handleSwipe} direction="DIRECTION_VERTICAL">
            <div>
            <div className="end_screen">
                <div className="logo">
                    <img src={logo} alt="logo" />
                </div>

                <div style={{top: '35px',right: '10px'}} onClick={()=> this.setState({displayPopup: true})} className={Style.info}>
                    ?
                </div>

                <div className="new">
                    <img src={Kellogg} alt="nouveau" />
                </div>

                {/* <a href="http://cosavostra.com" target="_blank" rel="noopener noreferrer" className="btn btn--white">OÙ ACHETER</a> */}

                <div className="arrow_link" onClick={ () => this.props.startQuizz(false) }>
                    Recommencer
                    <div className="wrapper">
                        <span className="arrow" />
                        <span className="arrow" />
                        <span className="arrow" />
                    </div>
                </div>

            </div>
            { this.state.displayPopup && <Popup>
                    <p>*Dark & Furious = Sombre et furieux</p>
                    <hr />
                    <h2>Mentions légales</h2>
                    <p>Kellogg n’enregistre, ne traite, ne transmet et ne transfère aucune des données consommateurs, y compris l’adresse IP.</p>
                    <p>Pour votre santé, pratiquez une activité physique régulière. <a style={{color: '#fff'}} href="http://www.mangerbouger.fr/" target="_blank" rel="noopener noreferrer">www.mangerbouger.fr</a></p>
                    <button onClick={()=> this.setState({displayPopup: false})} type="button">OK</button>
                </Popup> }
            </div>
            </Hammer>
        )
    }
}
