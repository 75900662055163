import React, {Component} from 'react';
import { Spring } from 'react-spring/renderprops'
import Style from '../welcome/welcome.module.scss'
import CX from 'classnames/bind';
import { Popup } from '../shared/Popup';
import twitterIco from '../../assets/images/ico_twitter.png';
import facebookIco from '../../assets/images/ico_facebook.png';
import downloadIco from '../../assets/images/download_ico.png';
import emailIco from '../../assets/images/ico_mail.png';
import kellogHeader from '../../assets/images/kellog-header.png';
import Hammer from 'react-hammerjs';
import badass from '../../assets/images/badass.png';
import vener from '../../assets/images/vener.png';
import misterieux from '../../assets/images/misterieux.png';
import fou from '../../assets/images/fou.png';
import hero from '../../assets/images/hero.png';
import repFou from '../../assets/images/Resultat-Fou-Furieux.jpg'
import repMysterieux from '../../assets/images/Resultat-Mysterieux.jpg'
import repBadass from '../../assets/images/Resultat-Badass-Rebel.jpg'
import repVener from '../../assets/images/Resultat-VNR-de-la-life.jpg'
import repHero from '../../assets/images/Resultat-Dark-Hero.jpg'
import Kellog from '../../assets/images/boite.png'
import {
    FacebookShareButton,
    TwitterShareButton,
    EmailShareButton
  } from 'react-share';
import EndComponent from '../end/EndComponent';
import {isIOS, isSafari} from 'react-device-detect';

class Result extends Component {
    
    constructor(props) {
        super(props)
        this.state = {
            moreDetails: false,
            show_popup: false,
            end: false,
            results: [
            {
                score: this.range(0,1),
                avatar: misterieux,
                download: repMysterieux,
                title : 'Mystérieux',
                header_background: '#21a41e',
                text: {
                    title: "T’es cette personne super charismatique mais tellement mystérieuse qui intrigue tout son entourage. Têtu(e) voire arrogant(e) quelques fois, tu n’aimes pas trop te confier aux autres et tu la joues plutôt solitaire. Un vrai Badass 2.0 !",
                    bol_when_text: "A l’apéro, entouré(e) de tes admirateurs et admiratrices."
                }
            },
            {
                score: this.range(2,4),
                avatar: hero,
                download: repHero,
                title : 'Le Dark Hero',
                header_background: "#e02e10",
                text: {
                    title: "Intrépide et déterminé(e), tu aimes vivre dangereusement et prendre des risques pour sauver ton entourage. Mégalomane sur les bords, tu restes le roi/la reine de la plaisanterie pourrie inattendue. Mais on t’adore quand même !",
                    bol_when_text: "Dans ton dark-bol, après un dark-jus de fruit pour un vrai petit dej de champion"
                }
            },
            {
                score: this.range(5,5),
                avatar: fou,
                download: repFou,
                title : 'Le fou furieux',
                header_background: "#bdbebe",
                text: {
                    title: "Si tu lis ça, c’est qu’a priori tu es toujours en liberté. En fait, même les gens chelou te trouvent chelou : voilà où ça mène de n’avoir aucune limite. Tu es le/la barge imprévisible de la bande, le fou furieux/ la folle furieuse  toujours prêt(e) à tout (et surtout n’importe quoi), comme ça, juste pour le plaisir.",
                    bol_when_text: "Le roi/reine des mélanges chelous : Céréales à l’eau de coco, supplément glaçons l’hiver et raclette l’été c’est ton truc"
                }
            },
            {
                score: this.range(6,8),
                avatar: vener,
                download: repVener,
                title : 'Le VNR de la life',
                header_background: "#611ea4",
                text: {
                    title: "T’es le/la dur(e) à cuire qui cuit pas vraiment grand chose. D’une franchise extrême, t’es le gueulard/ la gueularde VNR de la bande qui donne toujours son avis sur tout, qu’on l'ait demandé ou pas...",
                    bol_when_text: "Insupportable jusqu’au bout, t’es le genre de mec/meuf à mettre du lait avant les céréales. On t’a cramé."
                }
            },
            {
                score: this.range(9,10),
                avatar: badass,
                title : 'Le Badass rebel',
                download: repBadass,
                header_background: "#107ce0",
                text: {
                    title: "T'es une vraie tête brûlée qui n'a peur de rien, le/la premier(e) à t’embarquer dans des aventures toujours plus folles . On peut te reprocher beaucoup de choses, mais pas de pas mettre de l'ambiance.",
                    bol_when_text: "Au petit déjeuner, en rentrant de soirée après avoir retourné la boite toute la nuit !"
                }
            }
            ]
        }
    }

    range(start, end) {
        return (new Array(end - start + 1)).fill(undefined).map((_, i) => i + start);
    }

    handleSwipe = (e) => {
        if( e.direction === 8 ) {
            if( this.state.moreDetails ) {
                this.setState({end:true})
            }else {
                this.setState({moreDetails:true}) 
            } 
        }
    }

    render() {

        const headerClasses = CX({
            reduce: this.state.moreDetails
        })

        const textClasses = CX({
            result_screen: true,
            reveal: this.state.moreDetails
        })
        
        const data = this.state.results.filter( d => d.score.includes( this.props.result ) );

        const email_body = (isIOS && isSafari) ? 
            `${data[0].text.title} <br><br> TON BOL DE TRÉSOR CHOCOLAT NOIR ? <br><br> ${data[0].text.bol_when_text}  <br><br> <img width="500" style="width: 100%; max-width: 500px;" src="https://www.darkandfuriousquiz.com/${data[0].download}" />` : 
            `${data[0].text.title} TON BOL DE TRÉSOR CHOCOLAT NOIR ? ${data[0].text.bol_when_text}` 

        return (
            data[0] !== undefined ? 
            !this.state.end ? 
            <Hammer onSwipe={this.handleSwipe} direction="DIRECTION_VERTICAL">
            <div className={textClasses}>
                 <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}>
                   { props => 
                        <header 
                                className={headerClasses}
                                style={{backgroundColor: data[0].header_background, ...props}}
                        >
                            <img src={Kellog} alt='kellog' style={{
                                    position: 'absolute',
                                    width: '130%',
                                    right: 'calc(-100% - 65px)',
                                    transform: 'skewY(-8deg) rotate(-9deg)',
                                    top: '-60px'
                            }}/>
                        </header>
                   }
                </Spring>

                <img className="logo_header" src={kellogHeader} alt="kellog-header" />

                <h1>Résultat</h1>
                <strong>{ data[0].title }</strong>
                <Spring
                    from={{ opacity: 0, right: '-100%' }}
                    to={{opacity: 1, right: '2%'}}
                >
                    {props => <img className={Style.darkChoc} style={{bottom: '20%',width:'95%',...props}} src={data[0].avatar } alt="result_avatar"/> }
                </Spring>

                <Spring
                    from={{ opacity: 0 }}
                    to={{ opacity: 1 }}>
                    { props => (
                        <div className='result_text'>
                            <p>{ data[0].text.title }</p>
                            <hr />
                            <h2>TON BOL DE TRÉSOR CHOCOLAT NOIR ?</h2>
                            <p>{ data[0].text.bol_when_text }</p>
                            <button className="btn" onClick={ () => this.setState({show_popup: true})}>Partager</button>
                        </div>
                    )}
                </Spring>


                <div className="arrow_link" onClick={ () => { 
                    if( this.state.moreDetails ) {
                        this.setState({end:true})
                    }else {
                        this.setState({moreDetails:true}) 
                    }         
                } } >
                    Continuer
                    <div className="wrapper">
                        <span className="arrow" />
                        <span className="arrow" />
                        <span className="arrow" />
                    </div>
                </div>

                { this.state.show_popup && <Popup>
                    <h3>Partage sur les réseaux sociaux avec le hashtag</h3>
                    <h1 className="upper">#dark&furiousquiz</h1>
                    <div className="share_btn">
                        
                        <a rel="noopener noreferrer" href={ data[0].download } target="_blank" download>
                            <img className='initial' src={downloadIco} alt='download' />
                        </a>

                        <EmailShareButton 
                            url="https://www.darkandfuriousquiz.com/"
                            subject="Kellogg's Trésor Dark & Furious quiz - Quel dark chocovore es-tu ?"
                            body={ email_body }
                        >
                            <img className='initial' src={emailIco} alt='email' />
                        </EmailShareButton>  
                        <FacebookShareButton
                            url='https://www.darkandfuriousquiz.com/'
                            quote={ data[0].text.title + "\r\n \r\n TON BOL DE TRÉSOR CHOCOLAT NOIR ? \r\n  \r\n  " + data[0].text.bol_when_text  }

                        >
                            <img className='initial' src={facebookIco} alt='email' />
                        </FacebookShareButton>
                        <TwitterShareButton
                            title={ data[0].text.title + "\r\n \r\n TON BOL DE TRÉSOR CHOCOLAT NOIR ? \r\n \r\n" + data[0].text.bol_when_text + "\r\n \r\n"  }
                            hashtags={['dark&furiousquiz']}
                            url="https://www.darkandfuriousquiz.com/"
                        >
                            <img className='initial' src={twitterIco} alt='twitter' />
                        </TwitterShareButton>
                        
                    </div>
                    <button onClick={ ()=> this.setState({end:true}) }>Suivant</button>
                </Popup> }
            </div></Hammer> : <EndComponent startQuizz={ this.props.startQuizz } />
        : null
        );
    }
}

export default Result;