import React, {Component} from 'react';
import Question from "../question/Question";
import Result from "./Result";

class Quizz extends Component {

    constructor(props) {
        super(props);
        this.state = {
            activeQuestion: 0,
            questions : [
                {
                    id: 1,
                    title: 'Le matin :',
                    answers : [
                        { pts: 1 , answer : 'Tu penses aux personnes à qui tu vas faire passer une sale journée.' },
                        { pts: 0 , answer : 'Tu te couches.' },
                    ]
                },
                {
                    id: 2,
                    title: 'Devant un miroir :',
                    answers : [
                        { pts: 1 , answer : 'Tu te dis que tu as vu pire. Les cernes ça fait badass.' },
                        { pts: 0 , answer : 'Y’a pas de reflet. C’est peut-être pour ça que tu ne supportes pas l’ail non plus...' },
                    ]
                },
                {
                    id: 3,
                    title: ' Si tu étais une musique tu serais :',
                    answers : [
                        { pts: 1 , answer : 'De l’électro minimale berlinoise.' },
                        { pts: 0 , answer : 'Du brutal death metal.'},
                    ]
                },
                {
                    id: 4,
                    title: 'Ton sport de prédilection :',
                    answers : [
                        { pts: 1 , answer : 'Tout casser.' },
                        { pts: 0 , answer : 'Courir après les gens la nuit.' },
                    ]
                },
                {
                    id: 5,
                    title: 'Tu saignes du doigt, comment réagis-tu ?',
                    answers : [
                        { pts: 1 , answer : 'Self contrôle, tu maîtrises la situation et pars chercher un kit de survie.' },
                        { pts: 0 , answer : 'Tu laisses couler (pour boire ton sang).' },
                    ]
                },
                {
                    id: 6,
                    title: 'Pendant un examen : ',
                    answers : [
                        { pts: 1 , answer : 'En totale rébellion avec ta scolarité, tu réponds aux trois questions que tu connais (Nom, prénom, date) et tu te casses.' },
                        { pts: 0 , answer : 'Tu lances des regards insistants à toute ta classe pour choper les réponses.' },
                    ]
                },
                {
                    id: 7,
                    title: 'Au cinéma devant un film d’horreur :',
                    answers : [
                        { pts: 1 , answer : "Ça t'ouvre l'appétit. Popcorn XXL oblige." },
                        { pts: 0 , answer : 'Tu trouves que le tueur pourrait faire un effort et être plus perspicace.' },
                    ]
                },
                {
                    id: 8,
                    title: 'Pendant un concert :',
                    answers : [
                        { pts: 1 , answer : 'La scène est ton plongeoir, le public ta piscine.' },
                        { pts: 0 , answer : 'Tu es déçu si tu tiens encore debout à la fin. ' },
                    ]
                },
                {
                    id: 9,
                    title: 'En vacances, t’es plutôt :',
                    answers : [
                        { pts: 1 , answer : 'En boite tous les soirs pour multiplier les conquêtes, le repos c’est pour les fragiles. ' },
                        { pts: 0 , answer : 'En mode détente et farniente, posey OKLM quoi.' },
                    ]
                },
                {
                    id: 10,
                    title: 'En soirée quand tu dragues t’es plutôt :',
                    answers : [
                        { pts: 1 , answer : 'Avec ta cible bien en tête, à tout savoir d’elle : son âge, où elle habite, le nom de son chien, le groupe sanguin de sa tante.' },
                        { pts: 0 , answer : 'Plus personne n’est assez fou pour continuer à t’inviter aux soirées.' },
                    ]
                }
            ],
            endQuizz: false,
            result: 0,
            tuto: true,
        }
    }

    nextQuestion = (response_id) => {
        
        const next = this.state.activeQuestion + 1;
        if( this.state.questions[ next ] !== undefined ) {
            this.setState({
                activeQuestion: this.state.activeQuestion + 1,
                result: this.state.result + this.state.questions[ next - 1 ].answers[response_id].pts
            })
        } else {
            this.setState({
                endQuizz: true,
                result: this.state.result + this.state.questions[ next - 1 ].answers[response_id].pts
            })
        }
    };

    render() {
        return (
            <>
                {
                    !this.state.endQuizz
                        ? <Question
                            active={this.state.activeQuestion}
                            questions={this.state.questions}
                            nextQuestion={this.nextQuestion}
                        /> 
                        : <Result startQuizz={ this.props.startQuizz } result={this.state.result} />
                }
            </>
        );
    }
}

export default Quizz;