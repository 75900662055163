import React from 'react';
import Style from './popup.module.scss';

export const Popup = (props) => {
    return( 
        <div className={Style.backdrop}>
            <div className={Style.popup}>
                <div className={Style.wrapper}>{props.children}</div>
            </div>
        </div> 
    )
}