import React, {Component} from 'react';
import './assets/style.scss';
import Welcome from "./components/welcome/Welcome";
import Quizz from "./components/quizz/Quizz";

class App extends Component {

  constructor(props){
    super(props);
    this.state = {
      startQuizz: false
    }
  }


  startQuizz = (val) => {
      this.setState({
          startQuizz: val
      })
  };

  render() {
    return (
        <div id="app">
          <div className="container">
                { !this.state.startQuizz ? <Welcome startQuizz={ this.startQuizz } /> : <Quizz startQuizz={ this.startQuizz }/> }
          </div>
        </div>
    );
  }
}

export default App;
